import React, { useState } from 'react'
import styled from 'styled-components'
import { theme } from '@damen/ui'
import { MultipleIndex } from '@/components/GlobalSearch/types'
import Refinement from '../Refinement'
import SearchBox from '../SearchBox'
import { FacetOption, RefinementListStyleProps, SortByItem, SortByOption4thSpecification } from '../types'
import Sort from '../Sort'
import { MobileFilterToggle } from './mobileFilterToggle'

type Props = {
  facets: FacetOption[]
  refinementComponentType: 'dropdown' | 'checkbox'
  multipleIndexes: MultipleIndex[]
  enableSearchbox: boolean
  searchBoxPlaceholder: string
  rightComponent?: React.ComponentType<React.PropsWithChildren<any>>
  sortByOptions?: SortByItem[]
  sortByOptions4thSpecification?: SortByOption4thSpecification[]
  sortByPlaceholder?: string
  initialSort?: string
  mobileFiltersTitle?: string
} & RefinementListStyleProps

const Left = styled.div.withConfig({
  shouldForwardProp: (prop) => !['refinementListDisplayOption', 'isOpen'].includes(prop),
})<RefinementListStyleProps>`
  overflow: ${({ isOpen }) => (isOpen ? `visible` : `hidden`)};
  transition: max-height 300ms ease-in-out;
  max-height: ${({ isOpen }) => (isOpen ? `9999px` : `0`)};

  ${({ refinementListDisplayOption }) =>
    refinementListDisplayOption === 'vertical'
      ? ` flex-direction: column; width: 100%;`
      : `flex-wrap: wrap; flex-grow: 120;`}

  @media ${theme.legacyMediaQueries.md} {
    overflow: unset;
    display: flex;
    flex-wrap: unset;
    margin-right: auto;
    margin-bottom: ${theme.layout.spacingSM};
    max-height: unset;
  }
`

const Right = styled.div`
  @media ${theme.legacyMediaQueries.md} {
    margin-bottom: ${theme.layout.spacingSM};
  }
`

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: ${theme.spacing.x1}px;
`

const RefinmentListComponent: React.FC<React.PropsWithChildren<Props>> = ({
  facets,
  refinementComponentType,
  multipleIndexes,
  enableSearchbox,
  searchBoxPlaceholder,
  rightComponent: RightComponent,
  sortByOptions = [],
  sortByOptions4thSpecification = [],
  sortByPlaceholder,
  initialSort,
  refinementListDisplayOption,
  mobileFiltersTitle,
}) => {
  const enableSort = sortByOptions.length > 0
  const [isOpen, setIsOpen] = useState(facets.length === 1 || (enableSort && facets.length === 0))

  const handleMobileToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Left refinementListDisplayOption={refinementListDisplayOption} isOpen={isOpen}>
        {facets.length > 0 && (
          <Refinement
            facets={facets}
            refinementComponentType={refinementComponentType}
            multipleIndexes={multipleIndexes}
          />
        )}
        {enableSort && (
          <Sort
            sortOptions={sortByOptions}
            sortPlaceholder={sortByPlaceholder}
            initialSort={initialSort}
            sortByOptions4thSpecification={sortByOptions4thSpecification}
          />
        )}
      </Left>
      <SearchWrapper>
        {(facets.length > 1 || (enableSort && facets.length >= 1)) && (
          <MobileFilterToggle
            handleMobileToggleClick={handleMobileToggle}
            isOpen={isOpen}
            searchEnabled={enableSearchbox}
            placeholder={mobileFiltersTitle}
          />
        )}
        {enableSearchbox && <SearchBox placeholder={searchBoxPlaceholder} />}
      </SearchWrapper>
      {RightComponent && (
        <Right>
          <RightComponent />
        </Right>
      )}
    </>
  )
}

export default RefinmentListComponent
