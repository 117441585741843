import React, { useMemo } from 'react'
import { useHits, useInstantSearch } from 'react-instantsearch-core'
import { useMobile } from '@/hooks/mobile'
import { ThreeColumnResultsHitWrapper } from '../styles'
import { Props } from '../types'
import { isArrayOfAtLeastLenghtOne } from '@/utils/arrayUtils'
import { useIndexAlgoliaSearchOverviewContext } from '../../Context/indexAlgoliaSearchOverviewContext'

const Hits: React.FC<React.PropsWithChildren<Props>> = ({
  hitComponent: Hit,
  emptyComponent: Empty,
  hitWrapperComponent: HitWrapper = ThreeColumnResultsHitWrapper,
  labelDefault,
  cmsSortOrder,
  skeletonLoader: SkeletonLoader,
  pageSize = 8,
}) => {
  const { hits } = useHits({})
  const { isMobile } = useMobile()
  const { status } = useInstantSearch()
  const { setHits } = useIndexAlgoliaSearchOverviewContext()

  useMemo(() => {
    if (hits) {
      setHits(hits)
    }
  }, [hits, setHits])

  if (status === 'loading' || status === 'stalled') {
    return (
      <HitWrapper key={`hits-default-loading-${status}`}>
        {new Array<number>(pageSize).map((index) => (
          <SkeletonLoader key={`hits-default-${status}-${index}`} />
        ))}
      </HitWrapper>
    )
  }

  if (hits.length === 0) {
    return <div>{Empty}</div>
  }

  const sortProducts = (models: any[], productOrder: string[]) => {
    if (!isArrayOfAtLeastLenghtOne(productOrder)) {
      return models
    }

    const sortOrderLookup: Record<string, string> = productOrder
      .map((value, index) => {
        return { value, index }
      })
      .reduce((previous, current) => {
        Object.assign(previous, { [current.value]: current.index })
        return previous
      }, {})

    return models.sort((xI: any, yI: any) => {
      const x = sortOrderLookup[xI.id] ?? Number.MAX_SAFE_INTEGER
      const y = sortOrderLookup[yI.id] ?? Number.MAX_SAFE_INTEGER
      if (x < y) {
        return -1
      }
      if (x > y) {
        return 1
      }
      return 0
    })
  }

  const sortedLocations = sortProducts(hits, cmsSortOrder)

  return (
    <HitWrapper>
      {sortedLocations.length > 0
        ? sortedLocations.map((hit) => (
            <Hit key={hit.objectID} id={hit.objectID} hit={hit} isMobile={isMobile} labelDefault={labelDefault} />
          ))
        : hits.map((hit) => <Hit key={hit.objectID} id={hit.objectID} hit={hit} isMobile={isMobile} labelDefault={labelDefault} />)}
    </HitWrapper>
  )
}

export default Hits
